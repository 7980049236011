import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query'
import { withLDProvider } from "launchdarkly-react-client-sdk";
import "regenerator-runtime/runtime.js";
import * as Sentry from "@sentry/react";

import { AUTH_FLOW_OAUTH_IMPLICIT, CommercePlatform } from "@spscommerce/ui-react";

import TitleTracker from "./components/TitleTracker";
import CheckRoles from "./components/CheckRoles";
import Loading from "./components/Loading";
import { switchOrgWithPrompt } from "./utils/Auth";
import AppConfig from "./App.config";

const { fromObject } = require("url-env");

const { env, url } = fromObject(AppConfig.envMap);

function removeHash(hash) {
    const hashArray = hash.split("/");
    if (hashArray[0] === "#") {
        return "/" + hashArray.slice(1).join("/");
    }
    return hash;
}

function AppWithoutLD(props) {
    const queryClient = new QueryClient();

    function onAuthRedirect(authResult) {
        localStorage.removeItem("triedToSilentOrgSwitch");
        localStorage.removeItem("silentOrgSwitchLocation");
        if (authResult.appState) {
            props.history.replace(removeHash(authResult.appState));
        } else {
            props.history.replace("/home");
        }
    }

    function onAuthError(err) {
        if (err.error === "login_required") {
            const newOrgId = localStorage.getItem("triedToSilentOrgSwitch");
            const userLocation = localStorage.getItem("silentOrgSwitchLocation");
            switchOrgWithPrompt(newOrgId, env, url, userLocation);
        } else {
            console.warn(err);
        }
    }

    return (
        <Sentry.ErrorBoundary fallback={"An error has occurred"}>
            <QueryClientProvider client={queryClient}>
                <CommercePlatform
                    frameStrategy="stand-alone"
                    // frameStrategy="stand-alone"
                    // EKS cluster will only be in test and prod, so tokens from Auth0 will
                    // only use test and prod identity ids
                    authFlow={AUTH_FLOW_OAUTH_IMPLICIT}
                    environmentMap={{
                        localhost: "test",
                        "cdn.dev.spsc.io": "test",
                        "cdn.test.spsc.io": "test",
                        "test.developercenter.spscommerce.com": "test",
                        "developercenter.spscommerce.com": "prod",
                    }}
                    authConfig={{
                        test: {
                            clientID: "95WzMD3pE5V3Fo1FhKh3TWkKKzCpvpG1",
                            redirectUri: window.location.origin,
                            realm: "identity-test",
                        },
                        prod: {
                            clientID: "RQCOLMEURd3q0hv3O8IxAxBFPVHF0qiw",
                            redirectUri: window.location.origin,
                            realm: "identity",
                        },
                    }}
                    onAuthRedirect={[onAuthRedirect, onAuthError]}
                    openToUrl={process.env.REACT_APP_OPEN_TO_URL}
                >
                    {(commercePlatform) => {
                        if (commercePlatform.currentUser) {
                            const { currentUser } = commercePlatform;
                            Sentry.setUser({
                                email: currentUser.email,
                                id: currentUser.id,
                                username: `${currentUser.first_name} ${currentUser.last_name}`,
                            });
                            window.intercomSettings = {
                                app_id: window.intercomAppId,
                                name: `${currentUser.first_name} ${currentUser.last_name}`,
                                email: currentUser.email,
                                user_id: currentUser.id,
                                company: {
                                    id: currentUser.organization.id,
                                    name: currentUser.organization.organization_name,
                                },
                            };
                        } else {
                            Sentry.configureScope((scope) => scope.setUser(null));
                        }
                        return (
                            <Fragment>
                                {commercePlatform.hasSession ? (
                                    <CheckRoles
                                        {...commercePlatform}
                                        location={props.location}
                                        roleIndependentPaths={AppConfig.roleIndependentPaths}
                                    />
                                ) : (
                                    <div className={`sps-page }`}>
                                        <div className={`sps-body sps-body--collapse-600`}>
                                            <Loading />
                                        </div>
                                    </div>
                                )}
                                <TitleTracker />
                            </Fragment>
                        );
                    }}
                </CommercePlatform>
            </QueryClientProvider>
        </Sentry.ErrorBoundary>
    );
}

const App = withLDProvider({
    clientSideID: window.ldKey,
    user: {
        anonymous: true,
    },
})(AppWithoutLD);

export default withRouter(App);
